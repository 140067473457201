import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  Output,
  EventEmitter,
  OnInit,
  SimpleChange,
  ChangeDetectionStrategy,
} from '@angular/core';

import { Config, Hand } from './interfaces';
import { Timer } from './countdown.timer';

@Component({
  selector: 'countdown',
  template: `<ng-content></ng-content>`,
  styles: [
    `
      :host {
        display: none;
      }
    `,
  ],
  host: { '[class.count-down]': 'true' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountdownComponent implements OnInit, OnChanges, OnDestroy {
  private frequency = 1000;
  private _notify: any = {};
  private hands: Hand[] = [];
  private left = 0;
  private paused = false;
  /** ä¸¤ç§æåµä¼è§¦åï¼æ¶é´ç»æ­¢æè°ç¨ `stop()` */
  private stoped = false;

  @Input()
  config: Config;
  @Output()
  start = new EventEmitter();
  @Output()
  finished = new EventEmitter();
  @Output()
  notify = new EventEmitter();
  @Output()
  event = new EventEmitter<{ action: string; left: number }>();

  constructor(private el: ElementRef, private timer: Timer) {}

  /** å¼å§ï¼å½ `demand: false` æ¶è§¦å */
  begin() {
    this.paused = false;
    this.start.emit();
    this.callEvent('start');
  }

  /** éæ°å¼å§ */
  restart(): void {
    if (!this.stoped) this.destroy();
    this.init();
    this.callEvent('restart');
  }

  /** åæ­¢ */
  stop() {
    if (this.stoped) return;
    this.stoped = true;
    this.destroy();
    this.callEvent('stop');
  }

  /** æåï¼éæªç»æ­¢ææï¼ */
  pause() {
    if (this.stoped || this.paused) return;
    this.paused = true;
    this.callEvent('pause');
  }

  /** æ¢å¤ */
  resume() {
    if (this.stoped || !this.paused) return;
    this.paused = false;
    this.callEvent('resume');
  }

  private callEvent(action: string) {
    this.event.emit({ action, left: this.left });
  }

  private init() {
    const me = this;
    me.config = Object.assign(
      <Config>{
        demand: false,
        leftTime: 0,
        template: '$!h!æ¶$!m!å$!s!ç§',
        effect: 'normal',
        varRegular: /\$\!([\-\w]+)\!/g,
        clock: ['d', 100, 2, 'h', 24, 2, 'm', 60, 2, 's', 60, 2, 'u', 10, 1],
      },
      me.config,
    );
    const el = me.el.nativeElement as HTMLElement;
    me.paused = me.config.demand;
    me.stoped = false;

    // åæmarkup
    const tmpl = el.innerHTML || me.config.template;
    me.config.varRegular.lastIndex = 0;
    el.innerHTML = tmpl.replace(
      me.config.varRegular,
      (str: string, type: string) => {
        // æ¶éé¢çæ ¡æ­£.
        if (type === 'u' || type === 's-ext') me.frequency = 100;

        // çæhandçmarkup
        let content = '';
        if (type === 's-ext') {
          me.hands.push({ type: 's' });
          me.hands.push({ type: 'u' });
          content =
            me.html('', 's', 'handlet') +
            me.html('.', '', 'digital') +
            me.html('', 'u', 'handlet');
        } else {
          me.hands.push({ type: type });
        }

        return me.html(content, type, 'hand');
      },
    );

    const clock = me.config.clock;
    me.hands.forEach((hand: Hand) => {
      const type = hand.type;
      let base = 100,
        i: number;

      hand.node = el.querySelector(`.hand-${type}`);
      // radix, bits åå§å
      for (i = clock.length - 3; i > -1; i -= 3) {
        if (type === clock[i]) {
          break;
        }

        base *= clock[i + 1];
      }
      hand.base = base;
      hand.radix = clock[i + 1];
      hand.bits = clock[i + 2];
    });

    me.getLeft();
    me.reflow(0, true);

    // bind reflow to me
    const _reflow = me.reflow;
    me.reflow = (count: number = 0) => {
      return _reflow.apply(me, [count]);
    };

    // æå»º notify
    if (me.config.notify) {
      me.config.notify.forEach((time: number) => {
        if (time < 1)
          throw new Error(`the notify config must be a positive integer.`);
        time = time * 1000;
        time = time - (time % me.frequency);
        me._notify[time] = true;
      });
    }

    me.timer.add(me.reflow, me.frequency);
    // show
    el.style.display = 'inline';

    this.timer.start();

    return me;
  }

  private destroy() {
    this.timer.remove(this.reflow);
    return this;
  }

  /**
   * æ´æ°æ¶é
   */
  private reflow(count: number = 0, force: boolean = false): void {
    const me = this;
    if (!force && (me.paused || me.stoped)) return;
    me.left = me.left - me.frequency * count;

    me.hands.forEach((hand: Hand) => {
      hand.lastValue = hand.value;
      hand.value = Math.floor(me.left / hand.base) % hand.radix;
    });

    me.repaint();

    if (me._notify[me.left]) {
      me.notify.emit(me.left);
      me.callEvent('notify');
    }

    if (me.left < 1) {
      me.finished.emit(0);
      me.stoped = true;
      me.callEvent('finished');
      me.destroy();
    }
  }

  /**
   * éç»æ¶é
   */
  private repaint(): void {
    const me = this;
    if (me.config.repaint) {
      me.config.repaint.apply(me);
      return;
    }

    let content: string;

    me.hands.forEach((hand: Hand) => {
      if (hand.lastValue !== hand.value) {
        content = '';

        me.toDigitals(hand.value, hand.bits).forEach((digital: number) => {
          content += me.html(digital.toString(), '', 'digital');
        });

        hand.node.innerHTML = content;
      }
    });
  }

  /**
   * è·ååè®¡æ¶å©ä½å¸§æ°
   */
  private getLeft(): void {
    const me = this;
    let left: number = me.config.leftTime * 1000;
    const end: number = me.config.stopTime;

    if (!left && end) left = end - new Date().getTime();

    me.left = left - (left % me.frequency);
  }

  /**
   * çæéè¦çhtmlä»£ç ï¼è¾å©å·¥å·
   */
  private html(con: string, className: string, type: string): string {
    switch (type) {
      case 'hand':
      case 'handlet':
        className = type + ' hand-' + className;
        break;
      case 'digital':
        if (con === '.') {
          className = type + ' ' + type + '-point ' + className;
        } else {
          className = type + ' ' + type + '-' + con + ' ' + className;
        }
        break;
    }
    return '<span class="' + className + '">' + con + '</span>';
  }

  /**
   * æå¼è½¬æ¢ä¸ºç¬ç«çæ°å­å½¢å¼
   */
  private toDigitals(value: number, bits: number): number[] {
    value = value < 0 ? 0 : value;
    const digitals = [];
    // ææ¶ãåãç§ç­æ¢ç®ææ°å­.
    while (bits--) {
      digitals[bits] = value % 10;
      value = Math.floor(value / 10);
    }
    return digitals;
  }

  ngOnInit() {
    this.init();
    if (!this.config.demand) this.begin();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  ngOnChanges(
    changes: { [P in keyof this]?: SimpleChange } & SimpleChanges,
  ): void {
    if (!changes.config.firstChange) {
      this.restart();
    }
  }
}
